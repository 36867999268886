import React from 'react'
import { Link } from 'gatsby'
import { Seo } from "../../../components/seo/default"
import { graphql } from "gatsby"
import ArticleImage from '../../../components/utils/ArticleImage'
import { ShareIcon } from '../../../icons'
import { Layout } from "../../../components/layout/layout"
import { StoreContext } from "../../../context/store-context"

const appUrl = process.env.GATSBY_APP_URL

export default function ArticlePage({
    data: { article, allProduct }
}) {
    const [filteredRecentProducts, setRecentProducts] = React.useState([])
    const { recentProducts } = React.useContext(StoreContext)

    const shareNative = () => {
        if (navigator.share) {
            navigator.share({
              url: `${appUrl}/blogs/magazine/${article.handle}`
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
    }

    React.useEffect(() => {
        if (recentProducts.length && allProduct?.nodes.length) {
            const tempRecentProducts = []
            recentProducts.forEach((recentProd, index) => {
                allProduct.nodes.forEach((prod) => {
                    if (recentProd.handle === prod.handle) tempRecentProducts.push({ ...recentProd, index })
                })
            })
            setRecentProducts(
                tempRecentProducts
                .sort((a, b) => {
                    return a.index - b.index
                })
                .slice(0, tempRecentProducts.length - 1)
                .reverse()
                .slice(0, 4)
            )
        }
    }, [recentProducts, allProduct])
    
    return (
        <Layout>
            <Seo
                title={`${article.title}`}
                description={article?.excerpt}
                image={article?.image?.url}
            />
            <section className='mx-auto max-w-screen-xl sm:py-8'>
                <div>
                    <div className="article-content sm:px-6 sm:py-2">
                        <div className="article-image w-full sm:w-1/2 sm:mt-0 sm:mr-0 sm:mb-4 sm:ml-10 sm:float-right">
                            <ArticleImage
                                image={article.image}
                                layout="fullWidth"
                                formats={["jpg","webp"]}
                                width={400}
                                alt={article.title}
                                placeholder="blurred"
                                loading={"eager"}
                                objectFit="contain"
                            />
                            <div className="py-4 flex items-center justify-between px-4">
                                <Link to={`/body-piercing-jewellery`} className="bg-gray-sm text-black py-2 px-4 text-sm font-semibold">Shop now</Link>
                                <button onClick={shareNative} className="p-1">
                                    <ShareIcon fill="#000" width={24} height={24} />
                                </button>
                            </div>
                        </div>
                        <div className='px-4 py-2 sm:px-0 sm:py-0'>
                            <h3 className="text-sm font-bold py-2">{article.title}</h3>
                            <div className={`text-sm py-3 article-description`} dangerouslySetInnerHTML={{__html: article.contentHtml}}></div>
                        </div>
                    </div>

                    {
                        filteredRecentProducts.length > 1 && (
                            <div className="hidden py-2 sm:grid sm:grid-cols-4 gap-3 mx-6">
                            {
                                filteredRecentProducts.map((item, index) => (
                                <div key={index}>
                                    <Link to={`/${item.handle}`} className="flex-100 block">
                                        <div className="w-full">
                                            <img src={item.image} alt={item.title} className="overflow-hidden whitespace-normal" />
                                        </div>
                                    </Link>
                                </div>
                                ))
                            }
                            </div>
                        )
                    }
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
  query($id: String!) {
    article: shopifyArticle(id: { eq: $id }) {
        id
        title
        handle
        excerpt
        contentHtml
        image {
            url
            width
            height
            id
            altText
        }
    }
    allProduct: allShopifyProduct {
        nodes {
            handle
        }
    }
  }
`