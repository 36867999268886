import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getArticleImageData } from "../../utils/helpers"

export default function ArticleImage({
  // Destructure the props that you are passing to the image data function
  image,
  width,
  height,
  formats,
  layout,
  backgroundColor,
  sizes,
  aspectRatio,
  options,
  placeholder,
  // Use ...rest for the GatsbyImage props
  ...props
}) {
  const imageData = getArticleImageData({
    image,
    width,
    height,
    formats,
    layout,
    backgroundColor,
    sizes,
    aspectRatio,
    options,
    placeholder
  })

  // Pass the image data and spread the rest of the props
  return <GatsbyImage image={imageData} {...props} placeholder="blurred" />
}
